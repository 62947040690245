import { Component, Vue, Watch } from 'vue-property-decorator';
import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { translateText } from '@/lang';
import { getComponentInfo } from '@/components/form-design/utils';
let FormId: string = ''; // 避免外部重名
@Component({
  name: 'EleProperties'
})
export default class extends Vue {
  public widget: WidgetModel = WidgetData;
  public operation: string = '';
  public statusList = [
    {
      id: 'default',
      label: translateText('lang_default')
    },
    {
      id: 'required',
      label: translateText('lang_required')
    },
    {
      id: 'disabled',
      label: translateText('lang_disable_')
    },
    {
      id: 'hidden',
      label: translateText('lang_hide')
    }
  ];

  // 缓存中的当前选中表单项
  get _activedWidget() {
    return this.$store.getters.activeWidget;
  }

  //缓存中获取 当前表格的整体配置
  get _formConfigs() {
    return this.$store.getters.formConfigs;
  }

  created() {
    this.getFormId(); // 通过路由传参  获取formId
    let widget = SystemUtil.cloneDeep(this._activedWidget);
    let originWiget = this.findDataDataSource(widget.compCode);
    if (originWiget && Object.keys(originWiget).length) {
      widget.dataSource = originWiget.dataSource;
    }
    this.widget = widget;
    let operation = '';
    this.statusList.forEach(element => {
      if (this.widget.properties[element.id]) {
        operation = element.id;
      }
    });
    this.operation = operation;
  }

  // 获取路由地址中的 formId
  getFormId() {
    if (this.$route.query && this.$route.query.formId) {
      FormId = this.$route.query.formId as string;
    }
  }

  /**
   * 数据源赋值 解决数据源丢失的问题
   * @param compCode 组件的compCode
   * @ruturns 返回所寻找的组件
   */
  findDataDataSource(compCode) {
    if (typeof compCode == 'string') {
      let result = getComponentInfo(this._formConfigs[FormId].container.components, compCode);
      return result;
    }
    return null;
  }

  // 根据 _formConfigs（表单全局属性）的值，修改widget（_formConfigs的值来自于  '/form/query/single'接口）
  changeWidgetFromFormConfigs() {
    //来自于 '/form/model/query/list'接口（所有组件）
    let result = SystemUtil.cloneDeep(this._activedWidget);
    // 当前表格的整体配置
    const properties = this._formConfigs[FormId].container.properties;

    // 将当前表格的整体配置信息,赋值给组件
    Object.keys(properties).map(keys => {
      result.properties[keys] = properties[keys];
    });
    return result;
  }

  // 改变操作状态
  changeStatus(val) {
    let widget = SystemUtil.cloneDeep(this.widget);
    this.statusList.forEach(element => {
      if (element.id == val) {
        widget.properties[element.id] = true;
      } else {
        widget.properties[element.id] = false;
      }
    });
    this.widget = widget;
  }
  @Watch('widget', { deep: true, immediate: false })
  widgetConfigWatcher(val) {
    let result = this._activedWidget;
    result.compName = val.compName;
    result.compCode = val.compCode;
    result.properties = val.properties;
    if (val.dataSource) result.dataSource = val.dataSource;

    if (val.node || val.dataSourceConfigs) {
      result.node = val.node;
      result.dataSourceConfigs = val.dataSourceConfigs;
    }
    this.$emit('change', result);
  }
}
